export const BASE_URL = "https://api.maybebaby.ai"
export const origin = "https://api.maybebaby.ai"
// export const ALIGN_API_URL = "https://alignapi.maybebaby.ai:8000"
// export const BOY_API_URL = "https://boyapi.maybebaby.ai:8000"
// export const GIRL_API_URL = "https://girlapi.maybebaby.ai:8000"
export const ALIGN_API_URL = "https://baby-gan-predictor-default.tenant-e5286a-devcore.knative.chi.coreweave.com"

export const BOY_API_URL = "https://baby-gan-predictor-default.tenant-e5286a-devcore.knative.chi.coreweave.com"
export const GIRL_API_URL = "https://baby-gan-predictor-default.tenant-e5286a-devcore.knative.chi.coreweave.com"


//ngrok

// export const BASE_URL = "http://localhost:3044";
// export const origin = "http://localhost:3044";

export const getToken = () => {
    return localStorage.getItem('token') || localStorage.getItem('regToken');
}

export const setToken = (value) => {
    localStorage.setItem('token', value);
}

export const removeToken = () => {
    localStorage.removeItem('token')
}

export const getUserInfo = () => {
    const info = localStorage.getItem('userInfo');
    if (info) {
        return JSON.parse(info)
    } else {
        return {}
    }
}

export const setUserInfo = (value) => {
    localStorage.setItem('userInfo', JSON.stringify(value));
}

export const removeUserInfo = () => {
    localStorage.removeItem('userInfo')
}

export const BusinessPlans = [
    {
        id: 1,
        heading: "Essential",
        price: "$34.99",
        // strikePay: "$47",
        // discountamount: "(25% ",
        // discountText: "Special Launch Price Discount)",
        subHading: "One Time Payment",
        buttonTxt: "Buy Now",
        planType: "one_time",
        listData: [
            "Cutting-edge AI for realistic baby images",
            "User-friendly interface, no technical expertise required",
            "Informs family planning decisions",
            "Eliminate the uncertainty of what a potential child may look like.",
            "Protects customer privacy and security, with all data kept confidential and secure.",
            "Results saved in your image gallery for 30 days of easy access.",
            "Results in just an hour or two (for faster results consider our monthly plans with VIP processing priority)",


        ]
    },
    {
        id: 2,
        heading: "Premium",
        price: "$39",
        strikePay: "$59.99",
        discountamount: "(30% ",
        discountText: "Special Launch Price Discount)",
        popular: "POPULAR",
        subHading: "Unlimited Monthly Plan",
        buttonTxt: "Buy Now",
        planType: "monthly_plan",
        listData: [
            " Enjoy unlimited image predictions without any additional costs.",
            " Experience faster and more accurate results as the AI algorithm adapts to your unique features and preferences over time.",
            "Receive a personalized experience tailored to your unique preferences.",
            "Benefit from continuous customer support and assistance throughout the subscription period.",
            " Have your results saved in a personal image archive with unlimited storage capacity.",
            " Enjoy a consistent user experience without interruptions or delays due to slower image processing times or additional costs.",
            " Track changes in your appearance over time by using the app regularly.Plus, gain early access to 24 / 7 AI advice coaching for new parents and couples in relationships.Gain priority waitlist access to a private community for customers who share valuable insights, tips, and information.",
        ]
    },
    {
        id: 3,
        heading: "Elite Baby Vision Plans",
        price: "$99",
        strikePay: "$399",
        discountamount: "(75% ",
        discountText: "Special Launch Price Discount)",
        subHading: "Unlimited Yearly Plan",
        buttonTxt: "Buy Now",
        planType: "life_time",
        listData: [
            "Unbeatable Value: Enjoy all monthly plan benefits at an even more attractive price point.",
            "Unmatched Savings: Experience significant cost reductions compared to both the one-time payment and monthly plan options.",
            "Year-long Freedom: Access an unlimited number of image predictions for an entire year, satisfying your curiosity anytime without extra monthly expenses.",
            "Seamless Curiosity: Explore as much as you want, whenever you want, without fretting about additional costs.",
            " Continuous Enjoyment: Relish the benefits of the app without interruption throughout the year.",
            "Unlimited Potential: Discover the limitless possibilities of image predictions for 365 days.",
            "Affordable Brilliance: Unlock exceptional value without breaking the bank.",
            "Economic Wisdom: Make the smart choice with a plan designed for maximum savings.",
            "No Compromises: Enjoy premium benefits without sacrificing your budget.",
            "Savings Without Sacrifice: Experience all the perks of the monthly plan at a price that won't empty your pockets.",

        ]
    }
]

export const getCorrectImage = (image) => {
    var temp = image.split("?")[0]
    return temp
}