import React from "react";
import Header from "../../MainHeader/Header";
import Footer from "../../../pages/footer/Footer";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <section className="about-new blog-new Privacyonly">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="AboutUshead">Privacy Policy</h3>
            </div>
          </div>
          <div className="row">
            <div className="termsPoint">
              <div className="privacyPolicy-point">
                <div className="termsheader">1. Introduction</div>
                <p className="terms-paragraph">
                  This Privacy Policy governs the manner in which Outfight LLC,
                  a Wyoming limited liability company, (“Company”) and its
                  Service, MaybeBaby.AI, collects, uses, maintains, and
                  discloses information collected from users (“User”).
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">2. Personal Identification Information</div>
                <p className="terms-paragraph">
                  We may collect personal identification information from Users
                  in a variety of ways, including, but not limited to, when
                  Users visit our site, register on the site, and in connection
                  with other activities, services, features, or resources we
                  make available on our Service.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">3. Non-Personal Identification Information</div>
                <p className="terms-paragraph">
                  We may collect non-personal identification information about
                  Users whenever they interact with our Service. Non-personal
                  identification information may include the browser name, the
                  type of computer and technical information about Users means
                  of connection to our Service.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">4.Web Browser Cookies </div>
                <p className="terms-paragraph">
                  Our Service may use “cookies” to enhance User experience.
                  User’s web browser places cookies on their hard drive for
                  record-keeping purposes and sometimes to track information
                  about them.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">5. Use of User Data </div>
                <p className="terms-paragraph">
                  By using the Service, you agree that we may use images
                  generated by Users and posted on social media platforms for
                  testimonials, advertisements, sales materials, and various
                  communications. We may also sell user data to other services
                  for referral marketing, lead generation, or other
                  opportunities.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">6. Minors</div>
                <p className="terms-paragraph">
                  The Service is not intended for use by individuals under the
                  age of 18. We do not knowingly collect personal data from such
                  individuals and reserve the right to delete any information we
                  believe to be in violation of this provision.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">7. Changes to This Privacy Policy </div>
                <p className="terms-paragraph">
                  The Company has the discretion to update this Privacy Policy
                  at any time. We encourage Users to frequently check this page
                  for any changes to stay informed about how we are helping to
                  protect the personal information we collect.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">8. Acceptance of These Terms</div>
                <p className="terms-paragraph">
                  By using this Service, you signify your acceptance of this
                  Policy. If you do not agree to this Policy, please do not use
                  our Service.
                </p>
              </div>

              <div className="privacyPolicy-point">
                <div className="termsheader">9. Contacting Us </div>
                <p className="terms-paragraph">
                  If you have any questions about this Privacy Policy, the
                  practices of this site, or your dealings with this site,
                  please contact us at 228 Park Ave S #72873, New York, NY 10003
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
