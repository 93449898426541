
// user
export const LOG_IN_REQUEST = "LOG_IN_REQUEST";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_ERROR = "LOG_IN_ERROR";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGOUT = "LOGOUT";

export const USER_VERIFY_EMAIL_REQUEST = "USER_VERIFY_EMAIL_REQUEST";
export const USER_VERIFY_EMAIL_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS";
export const USER_VERIFY_EMAIL_ERROR = "USER_VERIFY_EMAIL_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const VERIFY_FORGOT_PASSWORD_REQUEST = "VERIFY_FORGOT_PASSWORD_REQUEST";
export const VERIFY_FORGOT_PASSWORD_SUCCESS = "VERIFY_FORGOT_PASSWORD_SUCCESS";
export const VERIFY_FORGOT_PASSWORD_ERROR = "VERIFY_FORGOT_PASSWORD_ERROR";

export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";
export const SET_FATHER_IMAGE = "SET_FATHER_IMAGE"
export const SET_MOTHER_IMAGES = "SET_MOTHER_IMAGES"
export const SET_BABY_IMAGES = "SET_BABY_IMAGES"
export const SET_FAMILY_IMAGES_DATA = "SET_FAMILY_IMAGES_DATA"
export const SET_FATHER_BLOB = "SET_FATHER_BLOB"
export const SET_MOTHER_BLOB = "SET_MOTHER_BLOB"
export const CLEAR_DATA = "CLEAR_DATA"

