import loaderIcon from '../../asset/image/loader.gif'
const Loader = () => {
  
    return <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}
export default Loader;
